<template>
  <header-page :title="'Roles & Permissions'" :hasBackLink="false" />

  <grid-permissions
    v-if="activeTab === 'permissions' && hasAccessPermissions"
    v-model:columns="columns"
    v-model="rows"
    :options="options"
    @setPermission="savePermission"
    @setTab="setTab"
    :listTabs="listTabs"
  />

  <roles
    v-if="activeTab === 'roles'"
    @setTab="setTab"
    :listTabsPrors="listTabs"
  />
</template>

<script>
import HeaderPage from '@/components/HeaderPage.vue'
import GridPermissions from '@/components/GridPermissions.vue'
import Roles from '@/views/Roles.vue'
import { fetchData, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'RolesAndPermissions',
  components: { HeaderPage, GridPermissions, Roles },
  data() {
    return {
      options: {
        isEditable: hasAccess(permissions.update_permissions),
        isAdd: false,
        addButtonText: 'Add permission',
      },

      columns: [
        new GridColumn({
          key: 'key',
          text: 'key',
          isHide: true,
        }),
        new GridColumn({
          key: 'parent_id',
          text: 'parent_id',
          isHide: true,
        }),
        new GridColumn({
          key: 'parent_description',
          text: 'Section',
          width: '250px',
        }),
        new GridColumn({
          key: 'description_id',
          text: 'description_id',
          isHide: true,
        }),
        new GridColumn({
          key: 'description',
          text: 'Action',
          width: '250px',
        }),
      ],

      rows: [],

      listTabs: [],
      activeTab: '',
    }
  },

  computed: {
    hasAccessPermissions() {
      return hasAccess(permissions.get_permissions)
    },
  },

  async created() {
    this.loadCountTabs()
    if (this.hasAccessPermissions) await this.fetchData()

    this.setTab(this.activeTab)
  },

  methods: {
    loadCountTabs() {
      let listTabs = []
      if (hasAccess(permissions.get_permissions))
        listTabs.push({
          id: 'permissions',
          name: 'Permissions',
          isActive: true,
          isVisible: true,
        })

      if (hasAccess(permissions.get_roles))
        listTabs.push({
          id: 'roles',
          name: 'Roles',
          isActive: false,
          isVisible: hasAccess(permissions.get_roles),
        })

      let activeTab = this.$route?.query?.tab
        ? this.$route?.query?.tab
        : 'permissions'
      if (!listTabs.length) activeTab = ''
      else activeTab = listTabs[0].id
      this.listTabs = listTabs
      this.activeTab = activeTab
    },

    setTab(id) {
      if (!id) return

      this.listTabs.forEach((item) => {
        if (item.id === id) item.isActive = true
        else item.isActive = false
      })

      this.$router.push({ query: { tab: id } })
      this.activeTab = id
    },

    async savePermission({ role_id, description_id, parent_id, value, row }) {
      this.$store.commit('setIsLoading', true)
      let result = await fetchData(
        urls.permissions + `/${description_id}`,
        'POST',
        { role_id: role_id, parent_id: parent_id, value: value }
      )
      if (!result?.detail) {
        console.log('permission saved', result)
      } else {
        row[role_id] = !row[role_id]
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      if (this.isLoading) return

      this.$store.commit('setIsLoading', true)

      const createDynamicColumns = (responseData) => {
        responseData.forEach((element) => {
          this.columns.push(
            new GridColumn({
              key: element.role.id,
              text: element.role.role,
              width: '100px',
              cssClass: 'text-center',
              type: 'boolean',
            })
          )
        })
      }

      const findPermissionValue = (
        responseData,
        roleId,
        parent_id,
        description_id
      ) => {
        let role = responseData.find((element) => element.role.id === roleId)
        if (!role) return null

        let permission = role.permissions.find(
          (element) =>
            element.parent_id === parent_id &&
            element.description_id === description_id
        )
        if (permission) return permission.value
        return null
      }

      const createRows = (responseData) => {
        responseData[0].permissions.forEach((item) => {
          let cell = {}
          for (let key in this.columns) {
            let col = this.columns[key]
            if (col.key in item)
              cell = { ...cell, ...{ [col.key]: item[col.key] } }
            else {
              // Сюда записываются значение пермишинов для каждой роли
              cell = {
                ...cell,
                ...{
                  [col.key]: findPermissionValue(
                    responseData,
                    col.key,
                    item.parent_id,
                    item.description_id
                  ),
                },
              }
            }
          }

          if (
            this.rows.length &&
            this.rows[this.rows.length - 1].parent_id === cell.parent_id
          )
            cell.parent_description = ''

          this.rows.push(cell)
        })
      }

      let result = await fetchData(urls.permissions, 'GET')
      if (!result?.detail && result?.length) {
        createDynamicColumns(result)
        createRows(result)
      } else {
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },
  },
}
</script>
