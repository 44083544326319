<template>
  <div class="grid grid-permissions">
    <div class="speed-filters">
        <div class="speed-filters-block">
            <div class="speed-filters-items">
                <div class="speed-filters-item" 
                v-for="tab in listTabs" 
                :key="tab.id"
                :class="{active: tab.isActive}" 
                @click="$emit('setTab', tab.id)">
                   {{tab.name}}
                   <!--<div class="count">56</div>--> 
                </div>
            </div>
            <div class="speed-filters-buttons">
                <div v-if="options.isAdd" class="speed-filters-button" @click="addRow">
                    <div class="icon" v-html="Plus"></div>
                    <div class="text">{{options.addButtonText}}</div>
                </div>
            </div>
        </div>
        <div class="separator"></div>
    </div>
    <div class="scrolable-block">
        <table>
            <thead>
                <tr>
                    <template v-for="column in columsModel.filter(colFiltered => !colFiltered.isHide)" :key="column.key"> 
                        <td :class="{[column.cssClass]: column.cssClass}" :style="setWidthColumn(column)">
                            <div class="header-cell">
                                <div>{{column.text}}</div>
                            </div>
                        </td>
                    </template>
                        <td></td>
                </tr>
            </thead>        
            <tbody>
                <template v-if="rowsModel.length">
                    <template v-for="row in rowsModel" :key="row.id">
                        <tr>
                            <td v-for="column in columsModel.filter(colFiltered => !colFiltered.isHide)" :key="column.key"
                                :class="{[column.cssClass]: column.cssClass}" 
                                :style="setWidthColumn(column)">
                                                                
                                <div v-if="column.type === 'boolean'" class="checkbox-row"
                                    :class="{'checked': row[column.key]}" 
                                    v-html="!row[column.key] ? Checkbox : CheckboxCheckedRow" 
  
                                    @click="setPermission(column.key, row.description_id, row.parent_id , row[column.key], row)">
                                </div>
                                <span v-else>{{column.formatedValue({ value: row[column.key], row: row, rows: rowsModel/*,  options: optionsModel*/ })}}</span>
                            </td>
                            <td></td>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
  </div>
</template>

<script>
import { Checkbox, CheckboxCheckedRow } from '@/utils/icons'

export default {
    name: 'GridPermissions',
    emits: ['update:modelValue', 'setPermission', 'setTab'],
    
    props: {
        modelValue: {           
            type: Object,
            default: () => ({}),
        },
        columns: {           
            type: Object,
            default: () => ({}),
        },
        options: { default: {} },
        listTabs: {default: []}
    },

    data() {
        return {
            Checkbox, CheckboxCheckedRow,
        }
    },

    computed: {
        rowsModel: {
            get() {
                return this.modelValue
            },

            set(value) {
                this.$emit('update:modelValue', value)
            }
        },

        columsModel: {
            get() {
                return this.columns
            },

            set(value) {
                this.$emit('update:columns', value)
            }
        },

        currentUser() {
            return this.$store.state.currentUser
        }
    },

    methods: {
        setPermission(role_id, description_id, parent_id, value, row) {
            if(role_id === 1 ) return //запретить изменения значения пермишенов у суперадмина

            this.$emit('setPermission', { role_id, description_id, parent_id, value: !value, row })
            row[role_id] = !row[role_id]
        },

        setWidthColumn(column) {
           return column.width ? 'width: ' + column.width : ''
        },

        addRow() {

        },
    }
}
</script>

<style lang="scss">
    .grid-permissions {
        .scrolable-block table td {
            &.text-center {
                text-align: center;
                div {
                    display: inline-flex;
                }
            }
        }
    }
</style>
