<template>
  <div>
    <grid
      v-if="hasAccess"
      v-model:columns="columns"
      v-model="rows"
      :options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewRole"
      @saveRow="saveRole"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
      @setTab="setTab"
      :listTabs="listTabsPrors"
    />
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import NewRowRole from '@/components/NewRows/NewRowRole'
import { fetchData, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import { listMixin } from '@/utils/list-mixin'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'Roles',
  mixins: [listMixin],

  props: {
    listTabsPrors: { default: [] },
  },

  data() {
    return {
      options: new GrigOptions({
        isSelectable: false,
        isMultiselect: false,
        isMultisort: false,
        isEditable: hasAccess(permissions.update_role),
        isAdd: hasAccess(permissions.create_role),
        addButtonText: 'Add role',
        isDelete: hasAccess(permissions.delete_role),
        getDeleteContent: async (rows) => {
          if (!rows) return ``
          return `<div class="mb-10">Are you sure you want to delete role <b>&nbsp;${rows[0].role}</b>?</div>`
        },
      }),

      columns: [
        new GridColumn({
          key: 'role',
          text: 'Role',
        }),      
      ],
      newRole: {
        role: null,
      },
      newRowComponent: shallowRef(NewRowRole),
    }
  },

  computed: {
    hasAccess() {
      return hasAccess(permissions.get_roles)
    },
  },

  created() {
    if (!this.hasAccess) return

    this.fetchData()
  },

  methods: {
    setTab(id) {
      this.$emit('setTab', id)
    },

    saveNewRole(role) {
      this.setNewRole(role)
      this.addRow()
    },

    saveRole(role) {
      this.editRow(role)
    },

    newRoleToDefault() {
      for (let key in this.newRole) {
        this.newRole[key] = null
      }
    },

    setNewRole(role) {
      for (let key in role) {
        this.newRole[key] = role[key]
      }
    },

    async editRow(role) {
      this.$store.commit('setIsLoading', true)
      const id = role.id
      delete role.id

      let result = await fetchData(
        urls.roles + `/${id}`,
        'POST',
        this.bodyToSave(role)
      )
      if (!result?.detail) {
        let roleRow = this.rows.find((item) => item.id === id)
        for (const key in role) {
          if (key in roleRow) roleRow[key] = role[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.roles,
        'POST',
        this.bodyToSave(this.newRole)
      )
      if (!result?.detail) {
        const tmpRole = this.newRole
        this.rows.unshift({ ...{ id: result.id }, ...tmpRole })
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newRoleToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(id) {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(urls.roles + `/${id}`, 'DELETE')
      if (!result?.detail) {
        const idx = this.rows.findIndex((item) => item.id === id)
        this.rows.splice(idx, 1)
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      if (this.isLoading && !this.listTabs.length) return

      this.$store.commit('setIsLoading', true)
      let query = `page=${this.page}&per_page=${this.per_page}`
      let prevSearch_text = this.search_text.value
      if (this.search_text.value?.length > 2) {
        query += `&search_text=${this.search_text.value}`
      }

      query += this.queryToSort()

      let result = await fetchData(urls.roles, 'GET', null, query)

      this.rows = (result?.rows || []).map((item) => {
        return {
          id: item.id,
          role: item.role,
        }
      })

      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },
  },
}
</script>
